import React from "react"
import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"
import FramerIcon from "https://framer.com/m/FramerIcon-xxzX.js@18TBh5X0mp0ch6wsdPsy"
import { IconContext } from "react-icons"
import { FaLinkedin, FaReddit, FaFacebook } from "react-icons/fa"

const onHoverStyle = {
    scale: 1.05,
    cursor: "pointer",
}

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30,
}

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function ShareButton(props) {
    // Config Properties
    const { socialMedia, shareText, iconProperties, backgroundType } = props

    // Single Properpties
    const {
        title,
        tint,
        style,
        font,
        borderWidth,
        borderColor,
        shareCurrentUrl,
        urlToShare,
        backgroundImage,
        linear,
    } = props

    // Multi properties
    const {
        radius,
        topLeftRadius,
        topRightRadius,
        bottomRightRadius,
        bottomLeftRadius,
        isMixedRadius = false,
    } = props

    const {
        stylePadding,
        topPadding,
        rightPadding,
        bottomPadding,
        leftPadding,
        isMixedPadding = false,
    } = props

    const borderRadius = isMixedRadius
        ? `${topLeftRadius}px ${topRightRadius}px ${bottomRightRadius}px ${bottomLeftRadius}px`
        : `${radius}px`

    const padding = isMixedPadding
        ? `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px`
        : `${stylePadding}px`

    let background
    if (backgroundType == "Solid") {
        background = tint
    } else if (backgroundType == "Image") {
        background = `url(${backgroundImage})`
    } else if (backgroundType == "Linear") {
        const { degree, toColor, fromColor, opacityTo, opacityFrom } = linear
        const gradient = `${degree}deg, ${toColor} ${opacityTo}%, ${fromColor} ${opacityFrom}%`
        background = `linear-gradient(${gradient})`
    }

    const shareOnTwitter = (urlToShare) => {
        const shareUrl = urlToShare ? urlToShare : window.location.href
        const shareLink = encodeURI(shareText + shareUrl)
        const shareWindow = window.open(
            `https://twitter.com/intent/tweet?text=${shareLink}`,
            "_blank",
            "height=700,width=700,left=0,top=0,resizable=yes,scrollbars=yes,status=yes"
        )
        shareWindow.blur()
        window.focus()
    }

    const shareOnFacebook = (urlToShare) => {
        const shareUrl = urlToShare ? urlToShare : window.location.href
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
            "_blank",
            "height=400,width=400,left=100,top=100,resizable=yes,scrollbars=yes,status=yes"
        )
    }

    const shareOnReddit = (urlToShare) => {
        const shareUrl = urlToShare ? urlToShare : window.location.href
        window.open(
            `https://www.reddit.com/submit?url=${shareUrl}&title=${title}`,
            "_blank",
            "height=400,width=400,left=100,top=100,resizable=yes,scrollbars=yes,status=yes"
        )
    }

    const shareOnLinkedIn = (urlToShare) => {
        const shareUrl = urlToShare ? urlToShare : window.location.href

        window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
            "_blank",
            "height=400,width=400,left=100,top=100,resizable=yes,scrollbars=yes,status=yes"
        )
    }

    let Icon
    if (socialMedia == "Twitter") {
        Icon = (
            <svg
                viewBox="0 0 1200 1227"
                height={iconProperties.size}
                fill="none"
            >
                <path
                    fill={iconProperties.color}
                    fontSize={iconProperties.size}
                    d="M714.163 519.284 1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026ZM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026Z"
                />
            </svg>
        )
    } else if (socialMedia == "LinkedIn") {
        Icon = (
            <FaLinkedin
                sx={{
                    color: iconProperties.color,
                    fontSize: iconProperties.size,
                }}
            />
        )
    } else if (socialMedia == "Reddit") {
        Icon = (
            <FaReddit
                sx={{
                    color: iconProperties.color,
                    fontSize: iconProperties.size,
                }}
            />
        )
    } else if (socialMedia == "Facebook") {
        Icon = (
            <FaFacebook
                sx={{
                    color: iconProperties.color,
                    fontSize: iconProperties.size,
                }}
            />
        )
    }

    return (
        <motion.div
            style={{ ...style, ...containerStyle }}
            onClick={() => {
                if (!shareCurrentUrl) {
                    if (socialMedia == "Twitter") {
                        shareOnTwitter(urlToShare)
                    } else if (socialMedia == "LinkedIn") {
                        shareOnLinkedIn(urlToShare)
                    } else if (socialMedia == "Reddit") {
                        shareOnReddit(urlToShare)
                    } else if (socialMedia == "Facebook") {
                        shareOnFacebook(urlToShare)
                    }
                } else {
                    if (socialMedia == "Twitter") {
                        shareOnTwitter(null)
                    } else if (socialMedia == "LinkedIn") {
                        shareOnLinkedIn(null)
                    } else if (socialMedia == "Reddit") {
                        shareOnReddit(null)
                    } else if (socialMedia == "Facebook") {
                        shareOnFacebook(null)
                    }
                }
            }}
        >
            <motion.div
                style={{
                    borderRadius,
                    padding,
                    background,
                    fontSize: font.size,
                    fontWeight: font.weight,
                    color: font.color,
                    fontFamily: `'${font.fontFace}'`,
                    overflow: "visible",
                    borderWidth: borderWidth,
                    borderStyle: "solid",
                    borderColor: borderColor,
                    display: "flex",
                    alignItems: "center",
                    gap: iconProperties.gap,
                }}
                whileHover={onHoverStyle}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
            >
                <IconContext.Provider
                    value={{
                        color: iconProperties.color,
                        size: iconProperties.size,
                    }}
                >
                    {Icon}
                </IconContext.Provider>
                {title}
            </motion.div>
        </motion.div>
    )
}

addPropertyControls(ShareButton, {
    shareCurrentUrl: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    urlToShare: {
        type: ControlType.String,
        defaultValue: "https://framer.com",
        hidden(props) {
            return props.shareCurrentUrl != false
        },
    },
    socialMedia: {
        type: ControlType.Enum,
        options: ["Twitter", "Reddit", "Facebook", "LinkedIn"],
    },
    shareText: {
        type: ControlType.String,
        defaultValue: "Share on Twitter: ",
        description: "Additional text is only available for Twitter sharing.",
        hidden(props) {
            return props.socialMedia != "Twitter"
        },
    },
    iconProperties: {
        type: ControlType.Object,
        controls: {
            color: { type: ControlType.Color, defaultValue: "#fff" },
            size: { type: ControlType.Number, defaultValue: 20 },
            gap: { type: ControlType.Number, defaultValue: 10 },
        },
    },
    title: {
        title: "Label",
        type: ControlType.String,
        defaultValue: "Share on X",
    },
    backgroundType: {
        type: ControlType.Enum,
        options: ["Solid", "Linear", "Image"],
        defaultValue: "Solid",
    },
    backgroundImage: {
        type: ControlType.Image,
        hidden(props) {
            return props.backgroundType != "Image"
        },
    },
    linear: {
        type: ControlType.Object,
        controls: {
            toColor: { type: ControlType.Color, defaultValue: "#242424" },
            opacityTo: { type: ControlType.Number, defaultValue: 0 },
            fromColor: { type: ControlType.Color, defaultValue: "#323232" },
            opacityFrom: { type: ControlType.Number, defaultValue: 100 },
            degree: { type: ControlType.Number, defaultValue: 90, max: 360 },
        },
        hidden(props) {
            return props.backgroundType != "Linear"
        },
    },
    tint: {
        title: "Background Color",
        type: ControlType.Color,
        defaultValue: "#131415",
        hidden(props) {
            return props.backgroundType != "Solid"
        },
    },
    font: {
        type: ControlType.Object,
        controls: {
            fontFace: {
                type: ControlType.String,
                defaultValue: "Inter",
            },
            size: { type: ControlType.Number, defaultValue: 16 },
            color: { type: ControlType.Color, defaultValue: "#fff" },
            weight: {
                type: ControlType.Enum,
                defaultValue: "bold",
                options: ["normal", "bold", "bolder", "lighter"],
            },
        },
    },
    radius: {
        type: ControlType.FusedNumber,
        title: "Radius",
        defaultValue: 4,
        toggleKey: "isMixedRadius",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "topLeftRadius",
            "topRightRadius",
            "bottomRightRadius",
            "bottomLeftRadius",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    borderWidth: {
        type: ControlType.Number,
        defaultValue: 1,
    },
    borderColor: {
        type: ControlType.Color,
        defaultValue: "#222426",
    },
    stylePadding: {
        type: ControlType.FusedNumber,
        title: "Padding",
        defaultValue: 16,
        toggleKey: "isMixedPadding",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "topPadding",
            "rightPadding",
            "bottomPadding",
            "leftPadding",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
})

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "visible",
}
